import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['toggler', 'mobileMenu'];
  static values = { expanded: Boolean }

  connect() {
    this.render();
  }

  render() {
    if (this.expandedValue) {
      this.mobileMenuTarget.classList.remove('hidden');
      window.addEventListener('click', this.toggle)
    } else {
      window.removeEventListener('click', this.toggle)
      this.mobileMenuTarget.classList.add('hidden');
    }
  }

  expand(boolean) {
    this.expandedValue = boolean;
    this.togglerTarget.setAttribute('aria-expanded', this.expandedValue);
    this.render();
  }

  toggle() {
    event.stopPropagation();
    this.expand(!this.expandedValue)
  }
}
